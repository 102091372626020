<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details :title="sidebar_title" @close-sidebar="closeSidebar">
                <characteristic-details v-if="sidebar_characteristic"
                    :uuid="uuid"
                    :char-uuid="characteristics.selected.uuid"
                    
                    @added="onAddedCharacteristic"
                    @updated="onUpdatedCharacteristic"
                    @close-sidebar="closeSidebar"
                ></characteristic-details>

            </app-sidebar-details>
        </template>

        <div id="page-service-creating">
            <app-loader v-if="loading.service" fixed-on="desktop"></app-loader>

            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <router-link class="btn btn-back-to" :to="back_to">Back to Service Catalogue</router-link>

                        <div class="header-info">
                            <h1 class="heading"><span v-if="is_new">Adding</span> Service <span v-if="is_editing && original.Name">"{{ original.Name }}"</span></h1>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <div class="board">
                            <h3 class="heading">Service information</h3>

                            <app-loader v-if="loading.service || processing.service"></app-loader>

                            <app-error v-model="errors.service"></app-error>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="service.Name"        label="Service name"         type="text" :required="true" :error="errors.Name"        @change="errors.Name = null"        :disabled="loading.service || processing.service" :maxlength="64"></app-input>
                                <app-input :value="service.ServiceType" label="Service type"         type="text"  readonly></app-input>
                            </div>

                            <div class="input-line-dbl">
                                <button v-if="!readonlyMode" class="btn btn-small btn-primary" @click="save('service')" :disabled="loading.service || processing.service || !is_changed.service">Save</button>
                                <button v-if="!readonlyMode && charsNeedFixing" class="btn btn-small btn-primary" @click="fixchars()" :disabled="loading.service || processing.service">Fix Characteristics</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 characteristics">
                        <div class="board" v-if="show_characteristic_group('throttling_speed_limits')">
                            <h3 class="heading">Throttling Speed Limits</h3>

                            <app-loader v-if="loading.product || processing.throttling_speed_limits" />

                            <app-error v-model="errors.throttling_speed_limits" />

                            <div class="input-line-dbl">
                                <app-dropdown-select
                                    v-if="show_characteristic('throttling_speed_limits', 'ocs.SpeedLimitDL')"

                                    v-model="service_characteristics['ocs.SpeedLimitDL'].DefaultValue"

                                    label="Speed Limit Download"

                                    :options="dictonaries.SpeedLimitDL"

                                    :readonly="readonlyMode"

                                    :error="errors['ocs.SpeedLimitDL']"
                                    :disabled="loading.product || processing.throttling_speed_limits"

                                    @change="errors['ocs.SpeedLimitDL'] = null"
                                />

                                <app-dropdown-select
                                    v-if="show_characteristic('throttling_speed_limits', 'ocs.SpeedLimitUL')"

                                    v-model="service_characteristics['ocs.SpeedLimitUL'].DefaultValue"

                                    label="Speed Limit Upload"

                                    :options="dictonaries.SpeedLimitUL"

                                    :readonly="readonlyMode"

                                    :error="errors['ocs.SpeedLimitUL']"
                                    :disabled="loading.product || processing.throttling_speed_limits"

                                    @change="errors['ocs.SpeedLimitUL'] = null"
                                />
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select
                                    v-if="show_characteristic('throttling_speed_limits', 'ocs.Throttle')"

                                    v-model="service_characteristics['ocs.Throttle'].DefaultValue"

                                    label="Throttle"

                                    :options="dictonaries.Throttle"

                                    :readonly="readonlyMode"

                                    :error="errors['ocs.Throttle']"
                                    :disabled="loading.product || processing.throttling_speed_limits"

                                    @change="errors['ocs.Throttle'] = null"
                                />

                                <app-dropdown-select
                                    v-if="show_characteristic('throttling_speed_limits', 'ocs.Tethering')"

                                    v-model="service_characteristics['ocs.Tethering'].DefaultValue"

                                    label="Tethering"

                                    :options="dictonaries.yes_no"

                                    :readonly="readonlyMode"

                                    :error="errors['ocs.Tethering']"
                                    :disabled="loading.product || processing.throttling_speed_limits"

                                    @change="errors['ocs.Tethering'] = null"
                                />
                            </div>

                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('throttling_speed_limits')" :disabled="loading.product || processing.throttling_speed_limits || !is_changed.throttling_speed_limits" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-12 characteristics">
                        <div class="board" v-if="show_characteristic_group('data')">
                            <h3 class="heading">Data</h3>

                            <app-loader v-if="loading.service || processing.data"></app-loader>

                            <app-error v-model="errors.data"></app-error>
                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['ocs.PurchaseDataAllowance'].DefaultValue"  label="Initial Data MB on purchase" type="tel" :error="errors['ocs.PurchaseDataAllowance']"  @change="errors['ocs.PurchaseDataAllowance'] = null"  :disabled="loading.service || processing.data" :maxlength="10" v-if="show_characteristic('data','ocs.PurchaseDataAllowance')"></app-input>
                                <app-dropdown-select :readonly="readonlyMode" v-model="service_characteristics['ocs.DataUsageRule'].DefaultValue"    label="Action at data cap"         :error="errors['ocs.DataUsageRule']"          @select="errors['ocs.DataUsageRule'] = null"          :disabled="loading.service || processing.data"                 v-if="show_characteristic('data','ocs.DataUsageRule')"
                                    :options="dictonaries.cap_throttled"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['ocs.RecurringDataAllowance'].DefaultValue" label="Monthly Data MB on renewal" type="tel" :error="errors['ocs.RecurringDataAllowance']" @change="errors['ocs.RecurringDataAllowance'] = null;" :disabled="loading.service || processing.data" :maxlength="10" v-if="show_characteristic('data','ocs.RecurringDataAllowance')"></app-input>
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['cellular.APNList'].DefaultValue"           label="Customer APN"               type="text" :required="true" :error="errors['cellular.APNList']"           @change="errors['cellular.APNList'] = null"           :disabled="loading.service || processing.data" :maxlength="255" :mask="mask['cellular.APNList']" v-if="show_characteristic('data','cellular.APNList')"></app-input>
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['FWA.ServiceAddress'].DefaultValue"         label="L2TP LNS Server"            type="text" :required="true" :error="errors['FWA.ServiceAddress']"           @change="errors['FWA.ServiceAddress'] = null"           :disabled="loading.service || processing.data" :maxlength="255" :mask="mask['FWA.ServiceAddress']" v-if="show_characteristic('data','FWA.ServiceAddress')"></app-input>
                                </div>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['cellular.SpeedUp'].DefaultValue"  label="Cellular Uplink Speed" type="tel" :error="errors['cellular.SpeedUp']"  @change="errors['cellular.SpeedUp'] = null"  :disabled="loading.service || processing.data" :maxlength="10" v-if="show_characteristic('data','cellular.SpeedUp')"></app-input>
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['cellular.SpeedDown'].DefaultValue" label="Cellular Downlink Speed" type="tel" :error="errors['cellular.SpeedDown']" @change="errors['cellular.SpeedDown'] = null;" :disabled="loading.service || processing.data" :maxlength="10" v-if="show_characteristic('data','cellular.SpeedDown')"></app-input>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="service_characteristics['ocs.PrepaidDataRollOverAllowed'].DefaultValue"    label="Data Rollover Allowed (Prepay)"         :error="errors['ocs.PrepaidDataRollOverAllowed']"          @select="errors['ocs.PrepaidDataRollOverAllowed'] = null"          :disabled="loading.service || processing.data"                 v-if="show_characteristic('data','ocs.PrepaidDataRollOverAllowed')"
                                    :options="dictonaries.yes_no"
                                ></app-dropdown-select>
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['ocs.MaxUnusedDataRollOver'].DefaultValue"  label="Max Rollover Data MB" type="tel" :error="errors['ocs.MaxUnusedDataRollOver']" @change="errors['ocs.MaxUnusedDataRollOver'] = null" :disabled="loading.service || processing.data" :maxlength="10" v-if="show_characteristic('data','ocs.MaxUnusedDataRollOver') && service_characteristics['ocs.PrepaidDataRollOverAllowed'].DefaultValue == 'Yes'"></app-input>
                            </div>

                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('data')" :disabled="loading.service || processing.data || !is_changed.data" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div>

                        <div class="board" v-else-if="show_characteristic_group('plan_ufb')">
                            <h3 class="heading">UFB Plan Settings</h3>

                            <app-loader v-if="loading.service || processing.plan_ufb"></app-loader>

                            <app-error v-model="errors.plan_ufb"></app-error>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['ufb.TermLength'].DefaultValue"  label="Term Length"   :required="true" :error="errors['ufb.TermLength']"  @change="errors['ufb.TermLength'] = null"  :disabled="loading.service || processing.plan_ufb" v-if="show_characteristic('plan_ufb', 'ufb.TermLength')"></app-input>
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['ufb.LFCPlanName'].DefaultValue" label="LFC Plan Name" :required="true" :error="errors['ufb.LFCPlanName']" @change="errors['ufb.LFCPlanName'] = null" :disabled="loading.service || processing.plan_ufb" v-if="show_characteristic('plan_ufb', 'ufb.LFCPlanName')"></app-input>
                            </div>

                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('plan_ufb')" :disabled="loading.service || processing.plan_ufb || !is_changed.plan_ufb" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div>

                        <div class="board" v-if="show_characteristic_group('voice')">
                            <h3 class="heading">Voice</h3>

                            <app-loader v-if="loading.service || processing.voice"></app-loader>

                            <app-error v-model="errors.voice"></app-error>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['ocs.PurchaseVoiceAllowance'].DefaultValue"  label="Initial Voice mins on purchase" type="tel" :error="errors['ocs.PurchaseVoiceAllowance']"  @change="errors['ocs.PurchaseVoiceAllowance'] = null" :disabled="loading.service || processing.voice" :maxlength="5" v-if="show_characteristic('voice','ocs.PurchaseVoiceAllowance')"></app-input>
                                <app-dropdown-select :readonly="readonlyMode" v-model="service_characteristics['ocs.VoiceUsageRule'].DefaultValue"    label="Action at voice cap"         :error="errors['ocs.VoiceUsageRule']"          @select="errors['ocs.VoiceUsageRule'] = null"          :disabled="loading.service || processing.voice"                 v-if="show_characteristic('voice','ocs.VoiceUsageRule')"
                                    :options="dictonaries.cap"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['ocs.RecurringVoiceAllowance'].DefaultValue" label="Monthly Voice mins on renewal" type="tel" :error="errors['ocs.RecurringVoiceAllowance']" @change="errors['ocs.RecurringVoiceAllowance'] = null" :disabled="loading.service || processing.voice" :maxlength="5" v-if="show_characteristic('voice','ocs.RecurringVoiceAllowance')"></app-input>
                                <app-dropdown-select :readonly="readonlyMode" v-model="service_characteristics['ocs.PrepaidCUGUsageRule'].DefaultValue"    label="Calls in Closed User Group"         :error="errors['ocs.PrepaidCUGUsageRule']"          @select="errors['ocs.PrepaidCUGUsageRule'] = null"          :disabled="loading.service || processing.voice"                 v-if="show_characteristic('voice','ocs.PrepaidCUGUsageRule')"
                                    :options="dictonaries.PrepaidCUGUsageRule"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="service_characteristics['ocs.PrepaidVoiceRollOverAllowed'].DefaultValue"    label="Voice Rollover Allowed (Prepay)"         :error="errors['ocs.PrepaidVoiceRollOverAllowed']"          @select="errors['ocs.PrepaidVoiceRollOverAllowed'] = null"          :disabled="loading.service || processing.data"                 v-if="show_characteristic('voice','ocs.PrepaidVoiceRollOverAllowed')"
                                    :options="dictonaries.yes_no"
                                ></app-dropdown-select>
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['ocs.MaxUnusedVoiceRollOver'].DefaultValue"  label="Max Rollover Voice Mins" type="tel" :error="errors['ocs.MaxUnusedVoiceRollOver']" @change="errors['ocs.MaxUnusedVoiceRollOver'] = null" :disabled="loading.service || processing.data" :maxlength="10" v-if="show_characteristic('voice','ocs.MaxUnusedVoiceRollOver') && service_characteristics['ocs.PrepaidVoiceRollOverAllowed'].DefaultValue == 'Yes'"></app-input>
                            </div>

                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('voice')" :disabled="loading.service || processing.voice || !is_changed.voice" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div>
                        
                        <div class="board" v-if="show_characteristic_group('sms')">
                            <h3 class="heading">SMS</h3>

                            <app-loader v-if="loading.service || processing.sms"></app-loader>

                            <app-error v-model="errors.sms"></app-error>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['ocs.PurchaseSMSAllowance'].DefaultValue"         label="Initial SMS on purchase" type="tel"                  :error="errors['ocs.PurchaseSMSAllowance']"         @change="errors['ocs.PurchaseSMSAllowance'] = null"         :disabled="loading.service || processing.sms" :maxlength="7" v-if="show_characteristic('sms','ocs.PurchaseSMSAllowance')"></app-input>
                                <app-dropdown-select :readonly="readonlyMode" v-model="service_characteristics['ocs.SMSUsageRule'].DefaultValue"    label="Action at SMS cap"         :error="errors['ocs.SMSUsageRule']"          @select="errors['ocs.SMSUsageRule'] = null"          :disabled="loading.service || processing.sms"                 v-if="show_characteristic('sms','ocs.SMSUsageRule')"
                                    :options="dictonaries.cap"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['ocs.RecurringSMSAllowance'].DefaultValue"        label="Monthly SMS on renewal" type="tel"                  :error="errors['ocs.RecurringSMSAllowance']"        @change="errors['ocs.RecurringSMSAllowance'] = null"        :disabled="loading.service || processing.sms" :maxlength="7" v-if="show_characteristic('sms','ocs.RecurringSMSAllowance')"></app-input>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="service_characteristics['ocs.PrepaidSMSRollOverAllowed'].DefaultValue"    label="SMS Rollover Allowed (Prepay)"         :error="errors['ocs.PrepaidSMSRollOverAllowed']"          @select="errors['ocs.PrepaidSMSRollOverAllowed'] = null"          :disabled="loading.service || processing.sms"                 v-if="show_characteristic('sms','ocs.PrepaidSMSRollOverAllowed')"
                                    :options="dictonaries.yes_no"
                                ></app-dropdown-select>
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['ocs.MaxUnusedSMSRollOver'].DefaultValue"  label="Max Rollover SMS" type="tel" :error="errors['ocs.MaxUnusedSMSRollOver']" @change="errors['ocs.MaxUnusedSMSRollOver'] = null" :disabled="loading.service || processing.data" :maxlength="10" v-if="show_characteristic('sms','ocs.MaxUnusedSMSRollOver') && service_characteristics['ocs.PrepaidSMSRollOverAllowed'].DefaultValue == 'Yes'"></app-input>
                            </div>

                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('sms')" :disabled="loading.service || processing.sms || !is_changed.sms" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div>
                        
                        <div class="board" v-if="show_characteristic_group('mms')">
                            <h3 class="heading">MMS</h3>

                            <app-loader v-if="loading.service || processing.mms"></app-loader>

                            <app-error v-model="errors.mms"></app-error>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['ocs.PurchaseMMSAllowance'].DefaultValue"  label="Initial MMS on purchase" type="tel" :error="errors['ocs.PurchaseMMSAllowance']"  @change="errors['ocs.PurchaseMMSAllowance'] = null"  :disabled="loading.service || processing.mms" :maxlength="7" v-if="show_characteristic('mms','ocs.PurchaseMMSAllowance')"></app-input>
                                <app-dropdown-select :readonly="readonlyMode" v-model="service_characteristics['ocs.MMSUsageRule'].DefaultValue"    label="Action at MMS cap"         :error="errors['ocs.MMSUsageRule']"          @select="errors['ocs.MMSUsageRule'] = null"          :disabled="loading.service || processing.mms"                 v-if="show_characteristic('mms','ocs.MMSUsageRule')"
                                    :options="dictonaries.cap"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['ocs.RecurringMMSAllowance'].DefaultValue" label="Monthly MMS on renewal" type="tel" :error="errors['ocs.RecurringMMSAllowance']" @change="errors['ocs.RecurringMMSAllowance'] = null" :disabled="loading.service || processing.mms" :maxlength="7" v-if="show_characteristic('mms','ocs.RecurringMMSAllowance')"></app-input>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="service_characteristics['ocs.PrepaidMMSRollOverAllowed'].DefaultValue"    label="MMS Rollover Allowed (Prepay)"         :error="errors['ocs.PrepaidMMSRollOverAllowed']"          @select="errors['ocs.PrepaidMMSRollOverAllowed'] = null"          :disabled="loading.service || processing.mms"                 v-if="show_characteristic('mms','ocs.PrepaidMMSRollOverAllowed')"
                                    :options="dictonaries.yes_no"
                                ></app-dropdown-select>
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['ocs.MaxUnusedMMSRollOver'].DefaultValue"  label="Max Rollover MMS" type="tel" :error="errors['ocs.MaxUnusedMMSRollOver']" @change="errors['ocs.MaxUnusedMMSRollOver'] = null" :disabled="loading.service || processing.data" :maxlength="10" v-if="show_characteristic('mms','ocs.MaxUnusedMMSRollOver') && service_characteristics['ocs.PrepaidMMSRollOverAllowed'].DefaultValue == 'Yes'"></app-input>
                            </div>

                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('mms')" :disabled="loading.service || processing.mms || !is_changed.mms" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div>

                        <div class="board" v-if="show_characteristic_group('prepay_credit')">
                            <h3 class="heading">Prepay Credit Details</h3>

                            <app-loader v-if="loading.service || processing.prepay_credit"></app-loader>

                            <app-error v-model="errors.prepay_credit"></app-error>

                            <div class="input-line-dbl" v-if="is_prerelease_mode">
                                <app-dropdown-select :readonly="readonlyMode" v-model="service_characteristics['ocs.PrepaidCreditExpiryDays'].DefaultValue"    label="Credit Expires after"      :error="errors.PrepaidCreditExpiryDays"          @select="errors.PrepaidCreditExpiryDays = null"          :disabled="loading.service || processing.mms"                v-if="show_characteristic('prepay_credit','ocs.PrepaidCreditExpiryDays')"
                                    :options="dictonaries.PrepaidCreditExpiryDays"
                                ></app-dropdown-select>
                                <app-dropdown-select :readonly="readonlyMode" v-model="service_characteristics['ocs.PrepaidRollOverExpiryDays'].DefaultValue"    label="Rollver Expires after"      :error="errors.PrepaidRollOverExpiryDays"          @select="errors.PrepaidRollOverExpiryDays = null"          :disabled="loading.service || processing.mms"                v-if="show_characteristic('prepay_credit','ocs.PrepaidRollOverExpiryDays')"
                                    :options="dictonaries.PrepaidRollOverExpiryDays"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['ocs.PurchaseCreditAmount'].DefaultValue"  label="Credit Granted on Purchase" type="tel" :error="errors['ocs.PurchaseCreditAmount']" @change="errors['ocs.PurchaseCreditAmount'] = null" :disabled="loading.service || processing.data" :maxlength="10" v-if="show_characteristic('prepay_credit','ocs.PurchaseCreditAmount')"></app-input>
                                <app-input :readonly="readonlyMode" v-model="service_characteristics['ocs.RecurringCreditAmount'].DefaultValue"  label="Credit Granted on Renewal" type="tel" :error="errors['ocs.RecurringCreditAmount']" @change="errors['ocs.RecurringCreditAmount'] = null" :disabled="loading.service || processing.data" :maxlength="10" v-if="show_characteristic('prepay_credit','ocs.RecurringCreditAmount')"></app-input>
                            </div>

                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('prepay_credit')" :disabled="loading.service || processing.prepay_credit || !is_changed.prepay_credit" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div>

                        <div class="board" v-if="show_characteristic_group('prepay_purchase') && is_prerelease_mode">
                            <h3 class="heading">Prepay Purchasing Rules</h3>

                            <app-loader v-if="loading.service || processing.prepay_purchase"></app-loader>

                            <app-error v-model="errors.prepay_purchase"></app-error>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="service_characteristics['IsBasePlan'].DefaultValue"    label="Is Base Plan?"       :error="errors['IsBasePlan']"          @select="errors['IsBasePlan'] = null"          :disabled="loading.service || processing.data"                 v-if="show_characteristic('prepay_purchase','IsBasePlan')"
                                    :options="dictonaries.yes_no"
                                ></app-dropdown-select>
                                <app-dropdown-select :readonly="readonlyMode" v-model="service_characteristics['AllowAutoRenew'].DefaultValue"    label="Allow Auto-Renew"       :error="errors['AllowAutoRenew']"          @select="errors['AllowAutoRenew'] = null"          :disabled="loading.service || processing.data"                 v-if="show_characteristic('prepay_purchase','AllowAutoRenew')"
                                    :options="dictonaries.yes_no"
                                ></app-dropdown-select>
                            </div>

                            <div class="input-line-dbl">
                                <app-dropdown-select :readonly="readonlyMode" v-model="service_characteristics['RequiresAnyBasePlan'].DefaultValue"    label="Purchase Requires Base Plan"       :error="errors['RequiresAnyBasePlan']"          @select="errors['RequiresAnyBasePlan'] = null"          :disabled="loading.service || processing.data"                 v-if="show_characteristic('prepay_purchase','RequiresAnyBasePlan')"
                                    :options="dictonaries.yes_no"
                                ></app-dropdown-select>
                                <app-dropdown-select :readonly="readonlyMode" v-model="service_characteristics['RequiresNoBasePlan'].DefaultValue"     label="Purchase Requires No Base Plan"    :error="errors['RequiresNoBasePlan']"          @select="errors['RequiresNoBasePlan'] = null"          :disabled="loading.service || processing.data"                 v-if="show_characteristic('prepay_purchase','RequiresNoBasePlan')"
                                    :options="dictonaries.yes_no"
                                ></app-dropdown-select>
                            </div>

                            <div class="btns">
                                <button class="btn btn-small btn-primary" @click="save('prepay_purchase')" :disabled="loading.service || processing.prepay_purchase || !is_changed.prepay_purchase" v-if="is_editing && !readonlyMode">Save</button>
                            </div>
                        </div> 
                    </div> -->
                </div>

                <!-- <div class="row">
                    <div class="col-12">
                        <div class="board">
                            <div class="btns">
                                <button v-if="!readonlyMode" class="btn btn-small btn-primary" @click="save('service')" :disabled="loading.service || processing.service || !is_changed.service">Save</button>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="row">
                    <div class="col-12">
                        <app-table
                            class="characteristics-table"

                            :class="readonlyMode ? 'readonly-mode' : 'edit-mode'"

                            :cols="characteristics.cols"
                            :rows="characteristics_list"

                            title="Characteristics"

                            :loading="loading.service || loading.characteristics"

                            :selected-row="characteristics.selected"
                            @details="showCharacteristicDetails"
                            @row-click-mobile="openCharacteristicDetails"

                            @remove="removeCharacteristic"
                        />

                        <div class="table-actions" v-if="is_editing">
                            <span></span>
                            <button v-if="!readonlyMode" class="btn btn-small btn-primary" @click="addCharacteristics">Add</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>



        <dialog-service-characteristic-remove
            v-if="dialogs.characteristic.remove.uuid"
            v-model="dialogs.characteristic.remove.show"

            :uuid="dialogs.characteristic.remove.uuid"

            @confirm="onCharacteristicRemoved"
        ></dialog-service-characteristic-remove>

    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import appInput from '@/components/app-input'
import appDropdownSelect from '@/components/app-dropdown-select'

import appSidebarDetails from '@/components/app-sidebar-details'
import characteristicDetails from '@/views/service/characteristic'

import appTable from '@/components/app-table'

import dialogServiceCharacteristicRemove from '@/views/service/dialogs/dialog-service-characteristic-remove'

import ServicesValidator from '@/validators/services-validator'
import ServiceCharacteristicsValidator from '@/validators/service-characteristics-validator'
import errMessage from '@/helpers/errMessage'
import formatDateNano from '@/helpers/format-date-nano'

const CENTS_CHARACTERISTICS = {
    'ocs.PurchaseBaseCost': true,
    'ocs.RecurringBaseCost': true,
    'ocs.PurchaseDataPlanCost': true,
    'ocs.RecurringDataPlanCost': true,

    'ocs.PurchaseCreditAmount': true,
    'ocs.RecurringCreditAmount': true,
}

const CHARACTERISTIC_GROUPS = {
    service: {
        'ocs.PurchaseBaseCost': null,
        'ocs.RecurringBaseCost': null,
        // 'ocs.PurchaseDataPlanCost': null,
        // 'ocs.RecurringDataPlanCost': null,
        'AllowCustomerPurchase': null,
        'CustomerVisible': null,
        'ocs.OfferPeriodCount': null,
        'ocs.OfferPeriodInterval': null,
    },
    data: {
        'ocs.PurchaseDataAllowance': null,
        'ocs.RecurringDataAllowance': null,
        'ocs.MaxUnusedDataRollOver': null,
        'ocs.DataUsageRule': null,
        'ocs.PrepaidDataRollOverAllowed': null,
        'cellular.SpeedDown': null,
        'cellular.SpeedUp': null,
        'cellular.APNList': null,
    },
    voice: {
        'ocs.PurchaseVoiceAllowance': null,
        'ocs.RecurringVoiceAllowance': null,
        'ocs.MaxUnusedVoiceRollOver': null,
        'ocs.VoiceUsageRule': null,
        'ocs.PrepaidVoiceRollOverAllowed': null,
        'ocs.PrepaidCUGUsageRule': null,
    },
    sms: {
        'ocs.PurchaseSMSAllowance': null,
        'ocs.RecurringSMSAllowance': null,
        // 'ocs.MaxUnusedSMSRollOver': null,
        'ocs.SMSUsageRule': null,
        'ocs.PrepaidSMSRollOverAllowed': null,
    },
    mms: {
        'ocs.PurchaseMMSAllowance': null,
        'ocs.RecurringMMSAllowance': null,
        // 'ocs.MaxUnusedMMSRollOver': null,
        'ocs.MMSUsageRule': null,
        'ocs.PrepaidMMSRollOverAllowed': null,
    },
    prepay_credit: {
        'ocs.PurchaseCreditAmount': null,
        'ocs.RecurringCreditAmount': null,
        'ocs.PrepaidCreditExpiryDays': null,
        'ocs.PrepaidRollOverExpiryDays': null,

        // 'ocs.RecurringBaseCost': null,
        // 'ocs.PurchaseDataPlanCost': null,
        // 'ocs.RecurringRetailPrice': null,
        // 'ocs.RecurringCreditAmount': null,
        // 'ocs.RecurringDataAllowance': null,
        // 'ocs.RecurringVoiceAllowance': null,
        // 'ocs.RecurringSMSAllowance': null,
        // 'ocs.RecurringMMSAllowance': null,
    },
    prepay_purchase: {
        'RequiresAnyBasePlan': null,
        'RequiresNoBasePlan': null,
        'AllowAutoRenew': null,
        'CustomerVisible': null,
    },
    plan_ufb: {
        'ufb.TermLength': null,
        'ufb.LFCPlanName': null,
    },
    throttling_speed_limits: {
        'ocs.SpeedLimitDL': null,
        'ocs.SpeedLimitUL': null,
        'ocs.Throttle':     null,
        'ocs.Tethering':    null,
    },
}

const PER_SERVICE_UI_ELEMENTS = {
    UNKNOWN: {
        service: {},
        data: {},
        voice: {},
        sms: {},
        mms: {},
        prepay_credit: {},
        prepay_purchase: {},
        plan_ufb: {},
        throttling_speed_limits: {},
    },
    fwa_ocs: {
        service: {
            ServiceVariant: true,
            'ocs.RecurringBaseCost': true,
        },
        data: {
            'ocs.PurchaseDataAllowance': true,
            'ocs.RecurringDataAllowance': true,
            'ocs.DataUsageRule': true,
            'cellular.SpeedDown': true,
            'cellular.SpeedUp': true,
            'cellular.APNList': true,
            // 'FWA.ServiceAddress': true,
        },
        sms: {
            'ocs.PurchaseSMSAllowance': true,
            'ocs.RecurringSMSAllowance': true,
            'ocs.SMSUsageRule': true,
        },
        throttling_speed_limits: {
            'ocs.SpeedLimitDL': true,
            'ocs.SpeedLimitUL': true,
            'ocs.Throttle':     true,
            'ocs.Tethering':    true,
        },
    },
    postpay_ocs: {
        service: {
            ServiceVariant: true,
            'ocs.RecurringDataPlanCost': true,
            'ocs.RecurringBaseCost': true,
        },
        data: {
            'ocs.PurchaseDataAllowance': true,
            'ocs.RecurringDataAllowance': true,
            'ocs.DataUsageRule': true,
            'cellular.APNList': true,
        },
        voice: {
            'ocs.PurchaseVoiceAllowance': true,
            'ocs.RecurringVoiceAllowance': true,
            'ocs.VoiceUsageRule': true,
        },
        sms: {
            'ocs.PurchaseSMSAllowance': true,
            'ocs.RecurringSMSAllowance': true,
            'ocs.SMSUsageRule': true,
        },
        mms: {
            'ocs.PurchaseMMSAllowance': true,
            'ocs.RecurringMMSAllowance': true,
            'ocs.MMSUsageRule': true,
        },
        throttling_speed_limits: {
            'ocs.SpeedLimitDL': true,
            'ocs.SpeedLimitUL': true,
            'ocs.Throttle':     true,
            'ocs.Tethering':    true,
        },
    },
    prepay_ocs: {
        service: {
            ServiceVariant: true,
            'ocs.OfferPeriodInterval': true,
            'ocs.OfferPeriodCount': true,
            // 'ocs.RecurringDataPlanCost': true,
            // 'ocs.RecurringBaseCost': true,
            // 'CustomerVisible': true,
            // 'AllowCustomerPurchase': true,
        },
        data: {
            // 'ocs.PurchaseDataAllowance': true,
            // 'ocs.DataUsageRule': true,
            // // 'ocs.MaxUnusedDataRollOver': true,
            // 'ocs.PrepaidDataRollOverAllowed': true,
            'cellular.APNList': true,
        },
        voice: {
            // 'ocs.PurchaseVoiceAllowance': true,
            // 'ocs.VoiceUsageRule': true,
            // // 'ocs.MaxUnusedVoiceRollOver': true,
            // 'ocs.PrepaidVoiceRollOverAllowed': true,
            // 'ocs.PrepaidCUGUsageRule': true,
        },
        sms: {
            // 'ocs.PurchaseSMSAllowance': true,
            // 'ocs.SMSUsageRule': true,
            // 'ocs.PrepaidSMSRollOverAllowed': true,
        },
        mms: {
            // 'ocs.PurchaseMMSAllowance': true,
            // 'ocs.MMSUsageRule': true,
            // 'ocs.PrepaidMMSRollOverAllowed': true,
        },
        prepay_credit: {
            // 'ocs.PrepaidCreditExpiryDays': true,
            // 'ocs.PrepaidRollOverExpiryDays': true,
            // 'ocs.PurchaseCreditAmount': true,
            // 'ocs.RecurringCreditAmount': true,
        },
        prepay_purchase: {
            // 'RequiresAnyBasePlan': true,
            // 'RequiresNoBasePlan': true,
            // 'AllowAutoRenew': true,
        },
        throttling_speed_limits: {
            'ocs.SpeedLimitDL': true,
            'ocs.SpeedLimitUL': true,
            'ocs.Throttle':     true,
            'ocs.Tethering':    true,
        },
    },
    prepaymobileAddon: {
        service: {
            ServiceVariant: true,
            'ocs.OfferPeriodInterval': true,
            'ocs.RecurringDataPlanCost': true,
            'ocs.RecurringBaseCost': true,
            'ocs.OfferPeriodInterval': true,
            'ocs.OfferPeriodCount': true,
            'CustomerVisible': true,
            'AllowCustomerPurchase': true,
        },
        data: {
            'ocs.PurchaseDataAllowance': true,
            'ocs.DataUsageRule': true,
            // // 'ocs.MaxUnusedDataRollOver': true,
            'ocs.PrepaidDataRollOverAllowed': true,
            'cellular.APNList': true,
        },
        voice: {
            'ocs.PurchaseVoiceAllowance': true,
            'ocs.VoiceUsageRule': true,
            // // 'ocs.MaxUnusedVoiceRollOver': true,
            'ocs.PrepaidVoiceRollOverAllowed': true,
            'ocs.PrepaidCUGUsageRule': true,
        },
        sms: {
            'ocs.PurchaseSMSAllowance': true,
            'ocs.SMSUsageRule': true,
            'ocs.PrepaidSMSRollOverAllowed': true,
        },
        mms: {
            'ocs.PurchaseMMSAllowance': true,
            'ocs.MMSUsageRule': true,
            'ocs.PrepaidMMSRollOverAllowed': true,
        },
        prepay_credit: {
            'ocs.PrepaidCreditExpiryDays': true,
            'ocs.PrepaidRollOverExpiryDays': true,
            'ocs.PurchaseCreditAmount': true,
            'ocs.RecurringCreditAmount': true,
        },
        prepay_purchase: {
            'IsBasePlan': true,
            'RequiresAnyBasePlan': true,
            'RequiresNoBasePlan': true,
            'AllowAutoRenew': true,
        },
        throttling_speed_limits: {
            'ocs.SpeedLimitDL': true,
            'ocs.SpeedLimitUL': true,
            'ocs.Throttle':     true,
            'ocs.Tethering':    true,
        },
    },
    UFB: {
        service: {
            ServiceVariant: true,
        },
        plan_ufb: {
            'ufb.TermLength':  true,
            'ufb.LFCPlanName': true,
        },
    },
}

const PER_SERVICE_CHARACTERISTICS = {
    prepay_ocs: [
        { Name: "ocs.PlanType",                DefaultValue: "",                                     Required: true,   ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.ServiceType",             DefaultValue: "Prepaid",                              Required: true,   ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.ProductVariant",          DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "sim.IMSI",                    DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.MSISDN",             DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PlanName",                DefaultValue: "vfflex_service_pre_combo_pack",        Required: false,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.PurchaseBaseCost",        DefaultValue: "",                                     Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.RecurringBaseCost",       DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.PurchaseDataPlanCost",    DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.RecurringDataPlanCost",   DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.PurchaseDataAllowance",   DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.RecurringDataAllowance",  DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.DataUsageRule",           DefaultValue: "Limited",                              Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.MaxUnusedDataRollOver",   DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseVoiceAllowance",  DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.RecurringVoiceAllowance", DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.VoiceUsageRule",          DefaultValue: "Limited",                              Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.MaxUnusedVoiceRollOver",  DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseSMSAllowance",    DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.RecurringSMSAllowance",   DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.SMSUsageRule",            DefaultValue: "Limited",                              Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        // { Name: "ocs.MaxUnusedSMSRollOver",    DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseMMSAllowance",    DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.RecurringMMSAllowance",   DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.MMSUsageRule",            DefaultValue: "Limited",                              Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        // { Name: "ocs.MaxUnusedMMSRollOver",    DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.OfferPeriodCount",        DefaultValue: "",                                     Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.OfferPeriodInterval",     DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PrepaidCUGUsageRule",     DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PrepaidDataRollOverAllowed",  DefaultValue: "",                                 Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PrepaidVoiceRollOverAllowed", DefaultValue: "",                                 Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PrepaidSMSRollOverAllowed",   DefaultValue: "",                                 Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PrepaidMMSRollOverAllowed",   DefaultValue: "",                                 Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseCreditAmount",        DefaultValue: "",                                 Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.RecurringCreditAmount",       DefaultValue: "",                                 Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PrepaidCreditExpiryDays",     DefaultValue: "",                                 Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PrepaidRollOverExpiryDays",   DefaultValue: "",                                 Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.SpeedLimitDL", DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.SpeedLimitUL", DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.Throttle",     DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.Tethering",    DefaultValue: "", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
    ],

    postpay_ocs: [
        { Name: "ocs.PlanType",                DefaultValue: "",                                     Required: true,   ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.ServiceType",             DefaultValue: "Postpaid",                             Required: true,   ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.ProductVariant",          DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "sim.IMSI",                    DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.MSISDN",             DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PlanName",                DefaultValue: "vfflex_esp_service_pos_plan",              Required: false,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.PurchaseBaseCost",        DefaultValue: "",                                     Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.RecurringBaseCost",       DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.PurchaseDataPlanCost",    DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.RecurringDataPlanCost",   DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.PurchaseDataAllowance",   DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.RecurringDataAllowance",  DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.DataUsageRule",           DefaultValue: "Limited",                              Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        // { Name: "ocs.MaxUnusedDataRollOver",   DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseVoiceAllowance",  DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.RecurringVoiceAllowance", DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.VoiceUsageRule",          DefaultValue: "Limited",                              Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        // { Name: "ocs.MaxUnusedVoiceRollOver",  DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseSMSAllowance",    DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.RecurringSMSAllowance",   DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.SMSUsageRule",            DefaultValue: "Limited",                              Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        // { Name: "ocs.MaxUnusedSMSRollOver",    DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseMMSAllowance",    DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.RecurringMMSAllowance",   DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.MMSUsageRule",            DefaultValue: "Limited",                              Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.SpeedLimitDL", DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.SpeedLimitUL", DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.Throttle",     DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.Tethering",    DefaultValue: "", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },

        { Name: "ocs.ESPReseller",        DefaultValue: "true",  Required: false, ValueType: "bool", ValidationRules:"", CanAdd: true, CanUpdate: true },

        { Name: "ocs.BlockPremiumCalls",  DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.PremiumCallsBarred", DefaultValue: "",      Required: false, ValueType: "string", ValidationRules:"", CanAdd: true, CanUpdate: true },

        { Name: "ocs.BlockPremiumSMS",    DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.PremiumSMSBarred",   DefaultValue: "",      Required: false, ValueType: "string", ValidationRules:"", CanAdd: true, CanUpdate: true },

        { Name: "ocs.OutgoingIDDBarred",  DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules:"", CanAdd: true, CanUpdate: true },
        { Name: "ocs.OutgoingTextBarred", DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules:"", CanAdd: true, CanUpdate: true },

        { Name: "ocs.RoamingBarred",              DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules:"", CanAdd: true, CanUpdate: true },
        { Name: "ocs.OutCallsWhileRoamingBarred", DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules:"", CanAdd: true, CanUpdate: true },
        { Name: "ocs.InCallsWhileRoamingBarred",  DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules:"", CanAdd: true, CanUpdate: true },
        { Name: "ocs.OOBWhileRoamingBarred",      DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules:"", CanAdd: true, CanUpdate: true },
        { Name: "ocs.ChargeableDataRGBarred",     DefaultValue: "false", Required: false, ValueType: "bool", ValidationRules:"", CanAdd: true, CanUpdate: true },
    ],


    fwa_ocs: [
        { Name: "ocs.PlanType",                DefaultValue: "FWA_???",                              Required: true,   ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.ServiceType",             DefaultValue: "",                                     Required: false,   ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.ProductVariant",          DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "sim.IMSI",                    DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.MSISDN",             DefaultValue: "",                                     Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PlanName",                DefaultValue: "vfflex_service_fwa_plan",              Required: false,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.PurchaseBaseCost",        DefaultValue: "",                                     Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.RecurringBaseCost",       DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.PurchaseDataPlanCost",    DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.RecurringDataPlanCost",   DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true },
        { Name: "ocs.PurchaseDataAllowance",   DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.RecurringDataAllowance",  DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "ocs.DataUsageRule",           DefaultValue: "Limited",                              Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ocs.PurchaseSMSAllowance",    DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.RecurringSMSAllowance",   DefaultValue: "0",                                    Required: false,  ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: false },
        { Name: "ocs.SMSUsageRule",            DefaultValue: "Limited",                              Required: false,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },

        { Name: "ocs.SpeedLimitDL", DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.SpeedLimitUL", DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.Throttle",     DefaultValue: "", Required: false, ValueType: "string", ValidationRules: "", CanAdd: true, CanUpdate: true },
        { Name: "ocs.Tethering",    DefaultValue: "", Required: false, ValueType: "bool", ValidationRules: "", CanAdd: true, CanUpdate: true },
    ],

    cellular: [
        { Name: "sim.IMSI",                    DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.MSISDN",             DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.APNList",            DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.DataEnabled5G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled4G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled2G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.DataEnabled3G",      DefaultValue: "true",                                 Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "cellular.SpeedUp",            DefaultValue: "64000000",                             Required: false, ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.SpeedDown",          DefaultValue: "256000000",                            Required: false, ValueType: "number", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.IMSI",               DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "sim.UUID",                    DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "cellular.AllowConferencing",  DefaultValue: "",                                     Required: false, ValueType: "bool",   ValidationRules:"", CanAdd:true,  CanUpdate: true  },
    ],

    comverse: [
        { Name: "comspm.telephone_number",     DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "comspm.cos_name",             DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "comspm.notify_email",         DefaultValue: "",                                     Required: false, ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
    ],

    cellularvoicemail: [
        { Name: "voicemail.MSISDN",               DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.OutcallNumberAddPart", DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.FaxMessageEnabled",    DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.FaxMSISDN",            DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.SecondaryVoiceMSISDN", DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.NotifyEmail",          DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.PIN",                  DefaultValue: "",  Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "voicemail.ClassOfService",       DefaultValue: "43", Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
    ],

    cellularmessaging: [
        { Name: "messaging.MSISDN",             DefaultValue: "",   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "messaging.BlockListEnabled",   DefaultValue: "",   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "messaging.BillingID",          DefaultValue: "15", Required: false, ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false },
        { Name: "messaging.BlockedNumbers",     DefaultValue: "",   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
        { Name: "messaging.Status",             DefaultValue: "",   Required: false, ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true },
    ],

    ufb: [
        { Name: "ufb.LocationID",                         DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ServiceProfile",                     DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.PrequalUUID",                        DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.PrimaryOnlyOffer",                   DefaultValue: "false",                                Required: true,  ValueType: "boolean", ValidationRules:"", CanAdd:false,  CanUpdate: false  },
        { Name: "ufb.PlanMarketSegment",                  DefaultValue: "Residential",                          Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false,  CanUpdate: false  },
        { Name: "ufb.OrderExistingONTID",                 DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.OrderExistingProductID",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.OrderBANDWIDTH_PROFILE",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },

        { Name: "ufb.ActiveASSURANCE_L1_SLA_LEVEL",       DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveASSURANCE_L2_SLA_LEVEL",       DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveATA_PORT_NUMBER",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveBANDWIDTH_PROFILE",            DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveBillingAccount",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveBITSTREAM_UID_1",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveBITSTREAM_UID_2",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveBITSTREAM_UID_3",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveBITSTREAM_UID_4",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveCIRCUIT_ID_INSERTION",         DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveCONNECT_RFS_DATE_TIME",        DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveDATA_HANDOVER_CONNECTION_ID",  DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveDATA_VLAN_CVID",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveDATA_VLAN_SVID",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveDISCONNECT_RFS_DATE_TIME",     DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveDOWNSTREAM_HP",                DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveDOWNSTREAM_LP_CIR",            DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveDOWNSTREAM_LP_EIR",            DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveIntactBandwidthProfile",       DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveIntactChargeType",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveIntactProductOfferID",         DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveIntactQuantity",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveIntactUnitCost",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveIntactValue",                  DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveITContactEmail",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveITContactName",                DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveITContactOrganisation",        DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveITContactPhone",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveMULTICLASS",                   DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveONT_ID",                       DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveORDER_DATE",                   DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveOrderContactEmail",            DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveOrderContactName",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveOrderContactOrganisation",     DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveOrderContactPhone",            DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveOrderId",                      DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveOrderItemId",                  DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActivePlaceDescription",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActivePlaceID",                      DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveProductBandwidthProfile",      DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveProductChargeType",            DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveProductID",                    DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveProductName",                  DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveProductProductOfferID",        DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveProductProductOfferName",      DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveProductQuantity",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveProductUnitCost",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveProductValue",                 DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveSiteContactEmail",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveSiteContactName",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveSiteContactOrganisation",      DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveSiteContactPhone",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveState",                        DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveUNI_BITSTREAM_UID_1",          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveUNI_BITSTREAM_UID_2",          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveUNI_BITSTREAM_UID_3",          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveUNI_BITSTREAM_UID_4",          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveUNI_PORT_NUMBER",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveUNI_PORT_TYPE",                DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveUNI_TAGGING",                  DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveUNIVLAN",                      DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveUPSTREAM_HP",                  DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveUPSTREAM_LP_CIR",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveUPSTREAM_LP_EIR",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveVOICE_HANDOVER_CONNECTION_ID", DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveSERVICE_GIVEN_DATE",           DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },
        { Name: "ufb.ActiveONTSerial",                    DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true, CanUpdate: true  },

        { Name: "ufb.FeasabilityDATA_HANDOVER_CONNECTION_ID",  DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityVOICE_HANDOVER_CONNECTION_ID", DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityAVAILABILITY_STATUS",          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityBUILD_REQUIRED",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityCONSENT_REQUIRED",             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityINSTALL_TYPE",                 DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityDEMAND_POINT_TYPE",            DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },
        { Name: "ufb.FeasabilityDESIGN_REQUIRED",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:false, CanUpdate: false  },

        { Name: "ufb.CVID",                                    DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.SVID",                                    DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.DesiredDateTime",                         DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.LocationID",                              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.OrderDate",                               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.PrequalUUID",                             DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.ServiceProfile",                          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.StaticIPv4",                              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.StaticIPv6",                              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.TransferCustomerAccountID",               DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.TransferCustomerInstanceID",              DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.TransferCustomerName",                    DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  }, 
        { Name: "ufb.contact.Email",                           DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.contact.Name",                            DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.contact.PhoneNumber",                     DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.customer.Email",                          DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.customer.Name",                           DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
        { Name: "ufb.customer.PhoneNumber",                    DefaultValue: "",                                     Required: true,  ValueType: "string", ValidationRules:"", CanAdd:true,  CanUpdate: true  },
    ],
}

export default {
    props: {
        readonlyMode: { type: Boolean, default: false },
    },

    components: {
        baseLayout,

        appLoader,
        appError,
        
        appInput,
        appDropdownSelect,

        appSidebarDetails,
        characteristicDetails,

        appTable,

        dialogServiceCharacteristicRemove,
    
    },

    data() {
        let baseData = {
            referrer: null,
            missingChars: {},

            mask: {
                'cellular.APNList': {
                    regex: String.raw`^\S{3,255}$`,
                },
            },

            service: {
                Name: null,
                ServiceType: null,
            },
            original: {},

            original_characteristics: {},
            service_characteristics: {
                //
                // NB: This list is auto-populated below, so VueJS creates watchers for them
                //
            },

            dictonaries: {
                cap: [
                    { id: 'Limited',   name: 'Block overage'   },
                    { id: 'Unlimited', name: 'Bill overage' },
                ],
                on_off: [
                    { id: 'On',   name: 'On'   },
                    { id: 'Off',  name: 'Off' },
                ],
                yes_no: [
                    { id: 'Yes',   name: 'Yes' },
                    { id: 'No',    name: 'No'  },
                ],
                days_months: [
                    { id: 'days',   name: 'Days' },
                    { id: 'months', name: 'Months'  },
                ],
                cap_throttled : [
                    { id: 'Limited',   name: 'Suspend Data' },
                    { id: 'Unlimited', name: 'Unlimited' },
                    { id: 'Throttled', name: 'Throttled' },
                ],
                PrepaidCreditExpiryDays : [
                    { id: '30', name: '30 days' },
                    { id: '31', name: '31 days' },
                    { id: '60', name: '60 days' },
                    { id: '90', name: '90 days' },
                    { id: '180', name: '180 days' },
                    { id: '270', name: '270 days' },
                    { id: '365', name: '365 days' },
                ],
                PrepaidRollOverExpiryDays : [
                    { id: '30', name: '30 days' },
                    { id: '31', name: '31 days' },
                    { id: '60', name: '60 days' },
                    { id: '90', name: '90 days' },
                    { id: '180', name: '180 days' },
                    { id: '270', name: '270 days' },
                    { id: '365', name: '365 days' },
                ],
                PrepaidCUGUsageRule : [
                    { id: 'Free', name: 'Free' },
                    { id: 'In_Bundle', name: 'Deduct Bundle mins' },
                    { id: 'Out_Bundle', name: 'Charge normally' },
                ],
                SpeedLimitDL: [
                    { id: '', name: 'Unlimited' },
                    { id: '500KBPS', name: '0.5M' },
                    { id: '1MBPS', name: '1M' },
                    { id: '1200KBPS', name: '1.2M' },
                    { id: '5MBPS', name: '5M' },
                    { id: '10MBPS', name: '10M' },
                    { id: '15MBPS', name: '15M' },
                    { id: '20MBPS', name: '20M' },
                    { id: '25MBPS', name: '25M' },
                    { id: '30MBPS', name: '30M' },
                    { id: '40MBPS', name: '40M' },
                    { id: '50MBPS', name: '50M' },
                    { id: '75MBPS', name: '75M' },
                    { id: '100BPS', name: '100M' },
                    { id: '150BPS', name: '150M' },
                    { id: '200BPS', name: '200M' },
                    { id: '250BPS', name: '250M' },
                    { id: '300BPS', name: '300M' },
                    // { id: 'limit1', name: 'Unlimited' },
                    // { id: 'limit2', name: '0.5M' },
                    // { id: 'limit3', name: '1M' },
                    // { id: 'limit4', name: '1.2M' },
                    // { id: 'limit5', name: '5M' },
                    // { id: 'limit6', name: '10M' },
                    // { id: 'limit7', name: '15M' },
                    // { id: 'limit8', name: '20M' },
                    // { id: 'limit9', name: '25M' },
                    // { id: 'limit10', name: '30M' },
                    // { id: 'limit11', name: '40M' },
                    // { id: 'limit12', name: '50M' },
                    // { id: 'limit13', name: '75M' },
                    // { id: 'limit14', name: '100M' },
                    // { id: 'limit15', name: '150M' },
                    // { id: 'limit16', name: '200M' },
                    // { id: 'limit17', name: '250M' },
                    // { id: 'limit18', name: '300M' },
                ],
                SpeedLimitUL: [
                    { id: '', name: 'Unlimited' },
                    { id: '500KBPS', name: '0.5M' },
                    { id: '1MBPS', name: '1M' },
                    { id: '1200KBPS', name: '1.2M' },
                    { id: '2MBPS', name: '2M' },
                    { id: '5MBPS', name: '5M' },
                    { id: '10MBPS', name: '10M' },
                    { id: '20MBPS', name: '20M' },
                    { id: '30MBPS', name: '30M' },
                    { id: '40MBPS', name: '40M' },
                    { id: '50MBPS', name: '50M' },
                    // { id: 'limit1', name: 'Unlimited' },
                    // { id: 'limit2', name: '0.5M' },
                    // { id: 'limit3', name: '1M' },
                    // { id: 'limit4', name: '1.2M' },
                    // { id: 'limit5', name: '2M' },
                    // { id: 'limit6', name: '5M' },
                    // { id: 'limit7', name: '10M' },
                    // { id: 'limit8', name: '20M' },
                    // { id: 'limit9', name: '30M' },
                    // { id: 'limit10', name: '40M' },
                    // { id: 'limit11', name: '50M' },
                ],
                Throttle: [
                    { id: '', name: 'Undefined' },
                    { id: 'No', name: 'Unlimited' },
                    { id: 'Throttle1.2M1.2M', name: 'Throttle1.2M1.2M' },
                    { id: 'Throttle2M2M', name: 'Throttle2M2M' },
                ],
            },
            
            characteristics: {
                list: [],
                selected: null,

                cols: [
                    { key: 'name',       title: 'Name',             highlight: true      },
                    { key: 'value',      title: 'Value'                                  },
                    { key: 'default',    title: 'Default Value'                          },
                    { key: 'required',   title: 'Required'                               },
                    { key: 'value_type', title: 'Value Type'                             },
                    { key: 'can_add',    title: 'Can Add'                                },
                    { key: 'can_update', title: 'Can Update'                             },

                ],
            },

            dialogs: {
                characteristic: {
                    remove: {
                        show: false,
                        uuid: null,
                    },
                },
                price: {
                    remove: {
                        show: false,
                        uuid: null,
                    },
                },
            },

            providers_spids: {},

            loading: {
                service: false,
                characteristics: false,
            },
            processing: {
                service: false,
                data: false,
                voice: false,
                sms: false,
                mms: false,
                plan_ufb: false,
                throttling_speed_limits: false,
            },

            errors: {},
        }

        for (const svcName in PER_SERVICE_CHARACTERISTICS) {
            const thisService = PER_SERVICE_CHARACTERISTICS[svcName]
            for (const i in thisService) {
                if (!thisService[i] || !thisService[i].Name) {
                    continue
                }
                baseData.service_characteristics[thisService[i].Name] = {}
                // baseData.service_characteristics[thisService[i].Name] = {
                //     DefaultValue: null,
                // }
                // console.log('thisService[i].Name', thisService[i].Name)
            }
        }

        for (const groupName in CHARACTERISTIC_GROUPS) {
            const thisGroup = CHARACTERISTIC_GROUPS[groupName]
            // console.log('groupName', groupName)
            for (const thisChar in thisGroup) {
                // console.log('  thisChar', thisChar)
                if (!thisGroup[thisChar]) {
                    continue
                }
                baseData.service_characteristics[thisChar] = {}
                // baseData.service_characteristics[thisChar] = {
                //     DefaultValue: null,
                // }
                // console.log('thisChar', thisChar)
            }
        }

        return baseData
    },

    created() {
        console.log('this.readonlyMode', this.readonlyMode)

        this.init()
    },

    methods: {
        init() {
            if (this.is_editing) {
                this.getServiceInfo()

                if (!this.readonlyMode) {
                    this.characteristics.cols.push({ action: 'details', icon: 'edit',              behavior: 'hidden'   })
                    this.characteristics.cols.push({ action: 'remove',  icon: 'remove',            behavior: 'detached' })
                }
            } else {
                this.service.ServiceType = this.service_type

                this.original = {...this.service}
                this.setCharDefaults(true, true)
            }
        },

        // Set default values for characteristics
        setCharDefaults(overrideValues, setRegardless) {

            let thisServiceType = this.type
            if (!thisServiceType) {
                thisServiceType = this.service.ServiceType
            }
            if (!thisServiceType) {
                console.log('setCharDefaults WARNING: no type')
                return
            }
            // Set this service types' defaults
            const thisServiceChars = PER_SERVICE_CHARACTERISTICS[this.type]
            // console.log('thisServiceChars', thisServiceChars)
            for (const charnum in thisServiceChars) {
                if (thisServiceChars[charnum].Name && (setRegardless || thisServiceChars[charnum].DefaultValue)) {
                    if (overrideValues || !this.service_characteristics[thisServiceChars[charnum].Name]) {
                        this.service_characteristics[thisServiceChars[charnum].Name] = thisServiceChars[charnum]
                        // console.log("prodType " + thisServiceChars[charnum].Name + " = " + thisServiceChars[charnum].DefaultValue)
                    }
                }
            }
        },


        /** Service */
        
        getServiceInfo() {
            this.loading.service = true
            
            this.$store.dispatch('api_service_catalog/getCachedServiceCatalogEntryByUUID', { UUID: this.uuid }).then(service => {
                // this.setService(service)

                this.getServiceСharacteristics().then(characteristics => {
                    this.setService(service, characteristics)
                    this.loading.service = false
                })

                // this.loading.service = false
            }).catch(error => {
                this.$store.dispatch('addToast', {
                    message: errMessage(error),
                    type: 'error',
                    delay: 5000,
                })

                console.log('Error api_service_catalog/getCachedServiceCatalogEntryByUUID', error)
                console.dir(error)

                this.$router.push({
                    ...this.back_to,
                    replace: true,
                })

                this.loading.service = false
            })
        },
        
        setService(service, characteristics) {
            let lastCharName = ''
            // console.group('setService(service, characteristics) {')
            // console.log('service', {...service})
            // console.log('characteristics', [...characteristics])

            this.service.Name = service.Name
            this.service.ServiceType = service.ServiceType

            // Set characteristics default values
            this.setCharDefaults(true, true)

            let justServiceChars = {}
            this.unknownChars = {}
            this.missingChars = {}
            // Move array into a map
            for (let i = 0, len = characteristics.length; i < len; i++) {
                const characteristic = characteristics[i]
                this.service_characteristics[characteristic.Name] =  { ...characteristic }
                justServiceChars[characteristic.Name] = characteristic
            }

            // Check for missing characteristics
            const thisServiceChars = PER_SERVICE_CHARACTERISTICS[service.ServiceType]
            for (const charnum in thisServiceChars) {
                if (!thisServiceChars[charnum].Name) {
                    continue
                }
                if (!(thisServiceChars[charnum].Name in justServiceChars)) {
                    console.log('we are missing', thisServiceChars[charnum].Name)
                    this.missingChars[thisServiceChars[charnum].Name] = thisServiceChars[charnum]
                    continue
                }
            }

            for (const charName in this.service_characteristics) {
                if (!this.valid_characteristic(charName)) {
                    continue
                }
                if (charName in CENTS_CHARACTERISTICS) {
                    if (this.service_characteristics[charName] && this.service_characteristics[charName].DefaultValue && this.service_characteristics[charName].DefaultValue.startsWith('$')) {
                        this.service_characteristics[charName].DefaultValue = this.service_characteristics[charName].DefaultValue.substring(1)
                    }
                    this.service_characteristics[charName].DefaultValue = '$' + (this.service_characteristics[charName].DefaultValue / 100).toFixed(2)
                }
                // This method is required for deep copying...
                this.original_characteristics[charName] = {...this.service_characteristics[charName]}
                lastCharName = charName
            }


            this.original = {...this.service}
// console.log('this.missingChars', this.missingChars)
// console.log('this.service_characteristics', this.service_characteristics)
            // console.log('this.service', {...this.service})
            this.$set(this.service_characteristics, 'x', {})
            this.$delete(this.service_characteristics, 'x')

            // console.groupEnd()
        },

        fixchars() {
            for (const key in this.processing) {
                this.processing[key] = true
            }
            let all_char_adds = []
            for (const charName in this.missingChars) {
                const payload = {
                    ...this.missingChars[charName],
                    ServiceCatalogEntryUUID: this.uuid,
                }
                console.log('Adding', payload)
                const request = this.$store.dispatch('api_service_catalog/AddServiceCatalogCharacteristic', payload).then(characteristic => {
                    if (charName in CENTS_CHARACTERISTICS) {
                        if (characteristic && characteristic.DefaultValue && characteristic.DefaultValue.startsWith('$')) {
                            characteristic.DefaultValue = characteristic.DefaultValue.substring(1)
                        }
                        characteristic.DefaultValue = '$' + (characteristic.DefaultValue / 100).toFixed(2)
                    }
                    this.service_characteristics[ charName ] = characteristic
                    this.original_characteristics[ charName ] = { ...characteristic }
                })
                .catch(error => {
                    this.errors[ charName ] = errMessage(error)
                })
                all_char_adds.push(request)
            }
            if (all_char_adds.length) {
                Promise.all(all_char_adds)
                    .then(() => {
                        this.missingChars = {}
                    })
                    .catch(()=>{})
                    .finally(() => {
                        for (const key in this.processing) {
                            this.processing[key] = false
                        }
                    })
            } else {
                for (const key in this.processing) {
                    this.processing[key] = false
                }
            }

        },

        save(target) {
            // console.group('save(target)')
            // console.log('this.is_new', this.is_new)
            // console.log('this.is_editing', this.is_editing)
            // console.log('target', this.is_new ? 'all' : target)
            // console.log('this.service', {...this.service})

            const saveAllCharacteristics = (service) => {                
                let requests_all = []             
                for (const charName in this.service_characteristics) {
                    if (!this.valid_characteristic(charName)) {
                        continue
                    }

                    let defaultValueToSave = this.service_characteristics[charName].DefaultValue
                    if (charName in CENTS_CHARACTERISTICS) {
                        if (defaultValueToSave && defaultValueToSave.startsWith('$')) {
                            defaultValueToSave = defaultValueToSave.substring(1)
                        }
                        defaultValueToSave = Math.floor(defaultValueToSave * 100).toString()
                    }

                    const request = this.$store.dispatch('api_service_catalog/AddServiceCatalogCharacteristic', {
                        ...this.service_characteristics[charName],
                        ServiceCatalogEntryUUID: service.UUID,
                        DefaultValue: defaultValueToSave,
                    }).then(characteristic => {
                        if (charName in CENTS_CHARACTERISTICS) {
                            if (characteristic && characteristic.DefaultValue && characteristic.DefaultValue.startsWith('$')) {
                                characteristic.DefaultValue = characteristic.DefaultValue.substring(1)
                            }
                            characteristic.DefaultValue = '$' + (characteristic.DefaultValue / 100).toFixed(2)
                        }
                        this.service_characteristics[ charName ] = characteristic
                        this.original_characteristics[ charName ] = { ...characteristic }
                    }).catch(error => {
                        this.errors[ charName ] = errMessage(error)
                    })

                    requests_all.push(request)
                }
                if (requests_all.length) {
                    Promise.all(requests_all)
                        .then(() => {})
                        .catch(()=>{})
                        .finally(() => {
                            for (const groupname in CHARACTERISTIC_GROUPS) {
                                this.processing[groupname] = false
                            }
                            this.uuid = service.UUID
                            this.$router.push({ name: 'service', params: { uuid: service.UUID } })
                        })
                } else {
                    for (const groupname in CHARACTERISTIC_GROUPS) {
                        this.processing[groupname] = false
                    }
                }

            }
            
            const saveTargetCharacteristics = (target) => {
                let requests_this_target = []
                for (const charName in CHARACTERISTIC_GROUPS[target]) {
                    if (!this.valid_characteristic(charName)) {
                        continue
                    }

                    if (this.original_characteristics[charName] && this.service_characteristics[charName].DefaultValue == this.original_characteristics[charName].DefaultValue) {
                        // skip over ones that haven't changed
                        continue
                    }
                    const action = this.service_characteristics[charName].UUID
                        ? 'api_service_catalog/UpdateServiceCatalogCharacteristic'
                        : 'api_service_catalog/AddServiceCatalogCharacteristic'
                    if (this.service_characteristics[charName].ServiceCatalogEntryUUID == '') {
                        this.service_characteristics[charName].ServiceCatalogEntryUUID = this.service.UUID
                    }

                    let defaultValueToSave = this.service_characteristics[charName].DefaultValue
                    if (charName in CENTS_CHARACTERISTICS) {
                        if (defaultValueToSave && defaultValueToSave.startsWith('$')) {
                            defaultValueToSave = defaultValueToSave.substring(1)
                        }
                        defaultValueToSave = Math.floor(defaultValueToSave * 100).toString()
                    }
                    const request = this.$store.dispatch(action, {
                        ...this.service_characteristics[charName],
                        DefaultValue: defaultValueToSave,
                    }).then(characteristic => {
                        if (charName in CENTS_CHARACTERISTICS) {
                            if (characteristic && characteristic.DefaultValue && characteristic.DefaultValue.startsWith('$')) {
                                characteristic.DefaultValue = characteristic.DefaultValue.substring(1)
                            }
                            characteristic.DefaultValue = '$' + (characteristic.DefaultValue / 100).toFixed(2)
                        }
                        this.service_characteristics[ charName ] = characteristic
                        this.original_characteristics[ charName ] = { ...characteristic }
                        // return Promise.resolve(characteristic)
                    }).catch(error => {
                        this.errors[ charName ] = errMessage(error)
                    })
                    requests_this_target.push(request)
                }

                if (requests_this_target.length) {
                    Promise.all(requests_this_target)
                        .then(() => {
                        })
                        .catch(()=>{})
                        .finally(() => {
                            this.processing[target] = false
                        })
                } else {
                    this.processing[target] = false
                }

                // console.group('const saveCharacteristics = (service) => {')
                // console.log('service', {...service})
                // console.log('this.service', {...this.service})
            }

            if (this.is_new) {
                if (this.validation('all')) {

                    for (const groupname in CHARACTERISTIC_GROUPS) {
                        this.processing[groupname] = true
                    }                            

                    for (const key in this.errors) {
                        console.log('New Service error:', key)
                        this.$delete(this.errors, key)
                    }

                    // let serviceName = this.service.Name
                    // if (this.service_characteristics['ocs.ServiceVariant'] && this.service_characteristics['ocs.ServiceVariant'].DefaultValue) {
                    //     serviceName = this.service_characteristics['ocs.ServiceVariant'].DefaultValue
                    // }
                    if (this.service_characteristics['ocs.ServiceVariant'] && this.service_characteristics['ocs.ServiceVariant'].DefaultValue == '') {
                        this.service_characteristics['ocs.ServiceVariant'].DefaultValue = this.service.Name
                    }
                    this.$store.dispatch('api_service_catalog/AddServiceCatalogEntry', {
                        Name: this.service.Name,
                        ServiceType: this.service.ServiceType,
                    })
                    .then(saveAllCharacteristics)
                    .catch(error => {
                        this.errors.service = errMessage(error)

                        for (const key in this.processing) {
                            this.processing[key] = false
                        }
                    })
                }
            } else {
                if (this.validation(target)) {
                    this.processing[target] = true

                    if (target == 'service') {
                        const payload = {}

                        payload.UUID = this.uuid

                        this.$store.dispatch('api_service_catalog/UpdateServiceCatalogEntry', {
                            UUID: this.uuid,
                            Name: this.service.Name,
                            // ServiceType: this.service.ServiceType, // Do not allow ServiceType to be edited
                        })
                        .then((service) => {
                            if (!this.service.UUID || this.service.UUID == '') {
                                this.service.UUID = service.UUID
                            }
                            saveTargetCharacteristics('service')
                        })
                        .catch(error => {
                            this.errors.service = errMessage(error)
                            this.processing.service = false
                        })

                    } else {
                        saveTargetCharacteristics(target)
                    }
                }
            }


            // api_service_catalog/AddServiceCatalogEntry
            // api_service_catalog/AddServiceCatalogCharacteristic

            // console.groupEnd()
        },

        validation(target) {
            // console.group('validation(target) {')
            console.log('target', target)
            // console.log('this.service', {...this.service})

            let is_valid = true
            this.errors = {}

            let values = {}

            if (target == 'service' || target == 'all') {
                values.Name = { DefaultValue: this.service.Name }
                values.ServiceType = { DefaultValue: this.service.ServiceType }
            }

            if (!CHARACTERISTIC_GROUPS[target] && target != 'all') {
                console.log('WARNING: Unable to validate target ', target)
                return true
            }

            if (target == 'all') {
                for (const groupname in this.available_characteristics_by_ui_group) {      
                    for (const charName in this.available_characteristics_by_ui_group[groupname]) {
                        if (!this.valid_characteristic(charName)) {
                            continue
                        }
                        // console.log('groupname', groupname, 'charName', charName)
                        if (this.service_characteristics[charName] != this.original_characteristics[charName] || ServicesValidator.isRequired(charName)) {
                            values[charName] = this.service_characteristics[charName]
                        }
                    }
                }
            } else {
                for (const charName in this.available_characteristics_by_ui_group[target]) {
                    if (!this.valid_characteristic(charName)) {
                        continue
                    }
                    // console.log('charName', charName)
                    if (this.service_characteristics[charName] != this.original_characteristics[charName] || ServicesValidator.isRequired(charName)) {
                        values[charName] = this.service_characteristics[charName]
                    }
                }
            }

            // console.log('values', values)

            for (const field in values) {
                // console.group(field)
                // console.log('ServicesValidator.isRuleExists(field)', ServicesValidator.isRuleExists(field))

                if (ServicesValidator.isRuleExists(field)) {
                    const error = ServicesValidator.isInvalid(field, values[field].DefaultValue)

                    if (error) {
                        console.log('validation error on', field, ':', error)
                        this.$set(this.errors, field, error)
                        // console.warn('this.errors', field, error)
                        is_valid = false
                    }
                }

                // console.groupEnd()
            }

            // console.log('>>> is_valid', is_valid)
            // console.groupEnd()

            return is_valid
        },



        /** Characteristics */

        getServiceСharacteristics() {
            this.loading.characteristics = true

            return this.$store.dispatch('api_service_catalog/FindServiceCatalogCharacteristicsPaginated', { ServiceCatalogEntryUUID: this.uuid }).then(response => {
                this.characteristics.list = response.ServiceCatalogCharacteristics.filter(item => !(item.Name in this.service))
                // console.warn('this.characteristics.list', this.characteristics.list)

                this.loading.characteristics = false

                return Promise.resolve(response.ServiceCatalogCharacteristics)
            }).catch(error => {
                this.$store.dispatch('addToast', {
                    message: errMessage(error),
                    type: 'error',
                    delay: 5000,
                })

                console.log('Error api_service_catalog/FindServiceCatalogCharacteristicsPaginated', error)
                console.dir(error)

                this.characteristics.list = []

                this.loading.characteristics = false

                return Promise.resolve([])
            })
        },

        showCharacteristicDetails(char) {
            if (this.characteristics.selected === char) {
                this.closeSidebar()
            } else {
                this.characteristics.selected = char
                this.openSidebar()
            }
        },
        openCharacteristicDetails(char) {
            this.$router.push({ name: 'service-characteristic', params: { uuid: this.uuid, char_uuid: char.uuid } })
        },

        addCharacteristics() {
            if (document.body.clientWidth <= this.$mobile_size) {
                this.$router.push({ name: 'service-characteristic', params: { uuid: this.uuid } })
            } else if (this.characteristics.selected !== null && !Object.keys(this.characteristics.selected).length) {
                this.closeSidebar()
            } else {
                this.characteristics.selected = {}
                this.openSidebar()
            }
        },
        onUpdatedCharacteristic(char) {
            this.getServiceСharacteristics()
        },
        onAddedCharacteristic(char) {
            this.getServiceСharacteristics().then(() => {
                this.characteristics.selected = this.characteristics_list.find(item => item.uuid == char.UUID)
            })
        },

        removeCharacteristic(char) {
            this.dialogs.characteristic.remove.show = true
            this.dialogs.characteristic.remove.uuid = char.uuid
        },
        onCharacteristicRemoved() {
            if (this.characteristics.selected && this.characteristics.selected.uuid == this.dialogs.characteristic.remove.uuid) {
                this.characteristics.selected = null
                this.closeSidebar()
            }
            this.dialogs.characteristic.remove.uuid = null

            this.getServiceСharacteristics()
        },

        /** Sidebar */
        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            switch (true) {
                case this.sidebar_characteristic: {
                    this.characteristics.selected = null
                } break
            }

            this.$emit('close-sidebar')
        },

        price(cents) {
            return `$${ ( cents / 100 ).toFixed(2) }`
        },

        valid_characteristic(charName) {
            // console.log('valid_characteristic', charName, (charName in this.service_characteristics), (typeof this.service_characteristics[charName]),  this.service_characteristics[charName] !== null)
            return (charName in this.service_characteristics) &&
                  typeof this.service_characteristics[charName] !== 'undefined' &&
                  typeof this.service_characteristics[charName] === 'object' &&
                  this.service_characteristics[charName] !== null &&
                  this.service_characteristics[charName].Name
        },

        show_characteristic(group, charName) {
            return this.available_characteristics_by_ui_group &&
                this.available_characteristics_by_ui_group[group] && 
                (charName in this.available_characteristics_by_ui_group[group]) &&
                this.valid_characteristic(charName)
        },

        show_characteristic_group(group) {
            if (this.available_characteristics_by_ui_group && this.available_characteristics_by_ui_group[group]) {
                const thisGroup = this.available_characteristics_by_ui_group[group]
                // Check to see if we have at least 1 characteristic in the given group
                for (const charName in thisGroup) {
                    if (this.show_characteristic(group, charName)) {
                        return true
                    }
                }                
            }
            return false
        },

    },

    computed: {
        ...mapGetters([
            'is_super_admin',

            'services_adding_types',
            'is_prerelease_mode',

            '$mobile_size',
        ]),

        charsNeedFixing() {
            return this.uuid && (Object.keys(this.missingChars).length > 0)
        },

        back_to() {
            return this.referrer && (this.referrer.name == 'services' || this.referrer.name == 'service-provider-services')
                ? this.referrer
                : this.is_super_admin
                    ? { name: 'services' }
                    : { name: 'service-provider-services' }
        },

        uuid() {
            return this.$route.params.type in this.services_adding_types
                ? null
                : this.$route.params.uuid
        },

        is_new() {
            return !this.is_editing
        },
        is_editing() {
            return Boolean(this.uuid)
        },

        type() {
            return this.$route.params.type
                || this.service.ServiceType
        },

        service_type() {
            return this.type in this.services_adding_types
                ? this.services_adding_types[this.type].service_type
                : this.type
        },

        is_base_service_changed() {
            if ((this.service.Name != this.original.Name) ||
                (this.service.ServiceType != this.original.ServiceType)) {
               return true
            }
            // for (const charName in CHARACTERISTIC_GROUPS['service']) {
            // }
            return false
        },

        is_changed() {
            // console.group('is_changed() {')

            let is_changed = {
                service: this.is_base_service_changed,
                data: false,
                voice: false,
                sms: false,
                mms: false,
                prepay_credit: false,
                prepay_purchase: false,
                plan_ufb: false,
                throttling_speed_limits: false,
            }

            for (const groupname in CHARACTERISTIC_GROUPS) {
                // console.log(groupname)
                for (const charName in CHARACTERISTIC_GROUPS[groupname]) {
                    // console.log("  ",charName,":", this.service_characteristics[charName])
                    if (!this.valid_characteristic(charName)) {
                        continue
                    }
                    if (!this.original_characteristics[charName]) {
                        // If we are missing original characteristics, then we are changed
                        is_changed[groupname] = true
                        // console.log('Characteristic does exist in the original version of this service -', charName)
                        break
                    }
                    if (this.original_characteristics[charName].DefaultValue != this.service_characteristics[charName].DefaultValue) {
                        // its changed
                        is_changed[groupname] = true
                        console.log('Characteristic has changed - ', charName, 'to', this.service_characteristics[charName].DefaultValue)
                        break
                    }
                    // console.log('No change - ', charName, ' => ', this.original_characteristics[charName].DefaultValue, this.service_characteristics[charName].DefaultValue)
                }
            }

            return is_changed
        },

        available_characteristics_by_ui_group() {
            if (this.type in PER_SERVICE_UI_ELEMENTS) {
                return PER_SERVICE_UI_ELEMENTS[this.type]
            }
            // console.log('Unsupported type:', this.type)
            return PER_SERVICE_UI_ELEMENTS['UNKNOWN']
        },

        characteristics_list() {
            let characteristics_list = []
            // for (const uuid in this.characteristics.list) {
            for (const charName in this.service_characteristics) {
                if (!this.valid_characteristic(charName)) {
                    console.log('not a valid char: ', charName)
                    continue
                }
            // console.log('characteristics_list ', charName)
                const item = this.service_characteristics[charName]

                characteristics_list.push({
                    uuid: item.UUID,
                    name: item.Name,
                    default: item.DefaultValue || '(blank)',
                    required: item.Required ? 'Yes' : 'No',
                    value: item.Value || '(none)',
                    value_type: item.ValueType || '(none)',
                    can_add: item.CanAdd ? 'Yes' : 'No',
                    can_update: item.CanUpdate ? 'Yes' : 'No',
                })
            }

            // console.log('characteristics_list', characteristics_list)
            return characteristics_list
        },

        sidebar_title() {
            return this.sidebar_price ? `Price details` : 'Characteristics details'
        },
        sidebar_characteristic() {
            return Boolean(this.characteristics.selected)
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-service-creating {
    margin: #{ 24px - 56px } 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    .header-info {
        display: flex;
        justify-content: space-between;
        width: fit-content;
        min-height: 62px;
        margin-bottom: 40px;
    }

    .col-12 {
        margin-top: 15px;

        &:first-child { margin: 0 };

        &.characteristics {
            display: flex;
            justify-content: stretch;
            align-items: stretch;
            flex-wrap: wrap;
            padding: 0;

            .board {
                flex-grow: 1;
                width: calc(50% - 30px);
                min-width: 365px;
                margin: 15px;
            }
        }
    }

    .board {
        padding: 24px;
        border-radius: $border-radius-secondary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-primary);
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .app-error {
            margin-top: 16px;
        }

        .input-line-dbl {
            width: 770px;
            max-width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:empty {
                display: none;
            }

            .app-input {
                width: calc(50% - 15px);
            }
            .app-dropdown-select {
                width: calc(50% - 15px);
            }
        }

        .app-input,
        .app-dropdown-select {
            margin: 24px 0 0;
            width: 770px;
            max-width: 100%;
        }

        .btns {
            flex-grow: 1;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
        }

        &>.btn,
        &+.btn,
        &>.btns .btn {
            margin-top: 32px;
        }

        .input-line-dbl {
            .btn {
                margin-top: 32px;
            }
        }
    }

    .characteristics-table {
        margin-top: 15px;

        &.readonly-mode {
            @include table-cols-width((150px, 150px, 150px, 90px, 90px, 50px, 50px), true);
        }

        &.edit-mode {
            @include table-cols-width((150px, 150px, 150px, 90px, 90px, 50px, 50px, 48px, 48px), true, 2);
        }
    }

    .table-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .table-actions {
        margin-top: 30px;
    }
}

@media (max-width: $tablet-size) {
    #page-service-creating {
        .header-info {
            width: 100%;
            margin-bottom: 48px;
            min-height: 56px;
        }

        .board {
            padding: 16px;
        }
        
        .characteristics-table {
            &.readonly-mode {
                @include table-cols-width((150px, 110px, 90px, 120px, 90px, 90px), true);
            }

            &.edit-mode {
                @include table-cols-width((150px, 110px, 90px, 120px, 90px, 90px, 40px, 40px), true, 2);
            }
        }

    }
}

@media (max-width: $mobile-size) {
    #page-service-creating {
        margin: #{ 24px - 56px } 0 64px;

        .btn-back-to {
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 8px;
        }

        .header-info {
            flex-direction: column;
            margin-bottom: 32px;
            min-height: 40px;
        }

        .col-12 {
            &.characteristics {
                margin-top: #{ 15px * .5 };

                .board {
                    width: 100%;
                    min-width: 0;
                    margin: #{ 15px * .5 };
                }
            }
        }

        .board {
            padding: 16px 8px 8px;

            &>.btn,
            &>.btns .btn {
                max-width: 100%;
            }

            &+.btn {
                margin-top: 15px;
            }
        }

        .characteristics-table {
            margin-top: #{ 15px * .5 };

            @include table-cols-width-mobile((85px, 160px, 24px), true);
        }

        .table-actions {
            margin-top: 15px;
        }
    }
}
</style>
