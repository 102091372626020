import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    'Name': {
        name: 'Service Name',
        required: true,
        maxlength: 64,
    },
    'ServiceType': {
        name: 'Service Type',
        required: true,
        maxlength: 64,
    },
    'Description': {
        name: 'Service Description',
        required: true,
        maxlength: 255,
    },

    'ocs.ServiceVariant': {
        name: 'Plan name',
        required: true,
        maxlength: 64,
    },

    'Description': {
        name: 'Service Description',
        required: false,
        maxlength: 255,
    },

    'ocs.RecurringBasePlanCost': {
        name: 'Default Plan monthly cost',
        format: /^[$]?\d{0,5}(\.\d{0,2})?$/
    },
    'ocs.RecurringDataPlanCost': {
        name: 'Data monthly cost',
        format: /^[$]?\d{0,5}(\.\d{0,2})?$/
    },

    'ocs.PurchaseDataAllowance': {
        name: 'Initial Data MB',
        format: /^\d{0,10}$/,
    },
    'ocs.RecurringDataAllowance': {
        name: 'Monthly Data MB',
        format: /^\d{0,10}$/,
    },
    'ocs.MaxUnusedDataRollOver': {
        name: 'Rollover Data',
        format: /^\d{0,5}$/,
    },

    'cellular.SpeedDown': {
        name: 'Download Speed',
        required: true,
        format: /^\d{0,10}$/,
    },
    'cellular.SpeedUp': {
        name: 'Upload Speed',
        required: true,
        format: /^\d{0,10}$/,
    },
    'ocs.RecurringDataAllowance': {
        name: 'Recurring Data Allowance',
        required: true,
        format: /^\d{0,10}$/,
    },
    'cellular.APNList': {
        name: 'Customer APN',
        required: true,
        format: /^\S{3,255}$/,
        custom_message: 'APN must be at least 3 characters',
    },

    'ocs.PurchaseVoiceAllowance': {
        name: 'Initial Voice mins',
        format: /^\d{0,5}$/,
    },
    'ocs.RecurringVoiceAllowance': {
        name: 'Monthly Voice mins',
        format: /^\d{0,5}$/,
    },
    'ocs.MaxUnusedVoiceRollOver': {
        name: 'Rollover Voice',
        format: /^\d{0,5}$/,
    },

    'ocs.PurchaseSMSAllowance': {
        name: 'Initial SMS',
        format: /^\d{0,7}$/,
    },
    'ocs.RecurringSMSAllowance': {
        name: 'Monthly SMS',
        format: /^\d{0,7}$/,
    },

    'ocs.PurchaseMMSAllowance': {
        name: 'Initial MMS',
        format: /^\d{0,7}$/,
    },
    'ocs.RecurringMMSAllowance': {
        name: 'Monthly MMS',
        format: /^\d{0,7}$/,
    },

    'ufb.TermLength': {
        name: 'Term Length',
        required: true,
    },
    'ufb.LFCPlanName': {
        name: 'LFC Plan Name',
        required: true,
        maxlength: 50,
    },

    'ocs.PrepaidCreditExpiryDays': {
        name: 'Credit Expiry',
        format: /^\d{0,7}$/,
    },
    'ocs.PrepaidRollOverExpiryDays': {
        name: 'Rollover Expiry',
        format: /^\d{0,7}$/,
    },
    'ocs.PurchaseCreditAmount': {
        name: 'Purchase Credit',
        format: /^[$]?\d{0,5}(\.\d{0,2})?$/
    },
    'ocs.RecurringCreditAmount': {
        name: 'Recurring Credit',
        format: /^[$]?\d{0,5}(\.\d{0,2})?$/
    },

    'ocs.OfferPeriodCount': {
        name: 'Offer Period Count',
        format: /^\d{0,3}$/
    },
    'ocs.OfferPeriodInterval': {
        name: 'Offer Period Interval',
        format: /^(days|months)?$/
    },
})